import React, { Component } from "react"
import GetOfferForm from "./get-offer-form"
import { OptionsForm } from "./options-form"

export class GetOfferBody extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h1 className="font-semibold text-2xl">Sell Your Sacramento House To Dahlia Homes Without Any Listing Fees & Open Houses</h1>
                            <p className="pt-4">Our goal at Dahlia Homes is to make this process the easiest way to sell your house in Sacramento. Fill out the form to receive an offer on your Sacramento home today!</p>
                            <h2 className="font-semibold text-2xl py-5">Sacramento homeowners:</h2>

                            <p>Do you have a house or property that you want to sell, but you are not looking forward to dealing with the stress, hassles, and fees that come with the traditional way of selling your Sacramento house through a real estate agent?</p>

                            <p className="pt-2">At Dahlia homes, our Sacramento Home Buyer program offers you a way to sell your Sacramento House without having to deal with real estate agents or wholesalers who are out to make a profit off you. We are a direct house
                                buyer organization and we focus on making the process as simple and easy as we can. We are a local company, which allows us to make fair and well-informed offers on your Sacramento property.</p>

                            <p className="py-4">Our offers are guaranteed, meaning that we will pay you EXACTLY what we offered you for your Sacramento House.
                                At Dahlia Homes, we are experienced home buyers who will give you a guaranteed offer on your Sacramento house and allow for flexible closing schedules to help you feel comfortable!
                            </p>

                        </div>

                        <div className="mx-auto py-4">

                            <div className="flex justify-center">
                                <GetOfferForm formName="Get Offer Page" />
                            </div>

                            <div className="flex justify-center">
                                <OptionsForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}