import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GetOfferBody } from "../components/get-offer-body"
import { InfoBanner } from "../components/info-banner"
import { SingleCard } from "../components/single-card"

const GetOfferPage = (props) => {
    let bgred = true
    return (
        <Layout>
            <Seo
                title="Get An Offer To Sell Your Sacramento House Quick & Easy"
                description="Get a FREE, no obligation offer from Dahlia Homes on your house. We make the selling process fast, simple,
                and worry free! We are here to help you sell your Sacramento Home for the best deal!"
                keywords={["sell your sacramento house","sacramento homeowners","get an offer on your sacramento house"]}
                location={props.location.pathname}
            />
            <div>
                <GetOfferBody />

                <InfoBanner bgred={bgred} />

                <SingleCard />

            </div>
        </Layout>
    )
}
export default GetOfferPage