import React, { Component } from "react"

export class SingleCard extends Component {
    render() {
        return (
            <div className="bg-dhgray pt-14 pb-10 lg:pt-28">
                <div className="flex justify-center px-5 mx-auto">

                    <div>
                        <div className="border rounded-xl bg-gradient-to-r from-dhredfrom to-dhredto mx-auto w-full h-fit shadow-2xl lg:-mt-12 2xl:-mt-[2.6rem]">
                            <p className="font-bold px-12 py-5 text-center text-white text-base lg:text-xl max-w-xl mx-auto">If You Answer “Yes” To Any Of These Questions,
                                Dahlia Homes Is Here To Help</p>
                            <hr className="border" />
                            <div className="bg-white px-20 text-lg rounded-b-xl text-sm xl:text-base">
                                <ul className="py-6">
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Are you going through foreclosure?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Do you own an unwanted property?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Frustrated with the hassles of tenants?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Do you own a vacant property?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Did you inherit an unwanted property?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Do you need to relocate quickly?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Do you want to avoid extra fees?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Are you going through a divorce?</p>
                                        </div>
                                    </li>
                                    <li className="flex py-2">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Do you own a “fixer-upper” that you don’t want to fix up?</p>
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <p>✅</p>
                                        <div className="ml-2">
                                            <p className="font-bold">Did you inherit an unwanted property?</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}